import { gql } from "@apollo/client";

export const CREATE_PROVIDER_PREFERENCE = gql`
  mutation createProviderPreference($input: CreateProviderPreferenceData!) {
    createProviderPreference(input: $input) {
      fullName
      providerName
      providerNumber
      verificationStatus
    }
  }
`;
