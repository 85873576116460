import { gql } from "@apollo/client";

export const GET_VOUCHERS = gql`
query vouchers{
  vouchers{
    id
    name
    slug
    createdAt
    creditPrice
    discount
    service
    referenceId
  }
}
`