import { useNavigation, useNotification } from "@pankod/refine-core";
import { Create, NumberInput, Select } from "@pankod/refine-mantine";
import { CREATE_EXCHANGE_RATE } from "graphql/exchange-rates/create-exchange-rate.mutation";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Currency } from "./list";

export interface ExchangeRateCreateInput {
  fromCurrencyId: string;
  toCurrencyId: string;
  rate: number;
}

export const currencyTypes: string[] = Object.keys(Currency).filter((key) =>
  isNaN(Number(key))
);

export const ExchangeRateCreate: React.FC = () => {
  const { push } = useNavigation();
  const { open } = useNotification();
  const [createExchangeRate, { loading }] = useMutation(CREATE_EXCHANGE_RATE);
  const [inputs, setInputs] = useState<ExchangeRateCreateInput>({
    fromCurrencyId: "",
    toCurrencyId: "",
    rate: 0,
  });
  const [availableCurrencies, setAvailableCurrencies] =
    useState<string[]>(currencyTypes);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const noneIsEmpty = Object.values(inputs).every((value) => value !== "");

    try {
      if (noneIsEmpty) {
        await createExchangeRate({
          variables: {
            input: {
              data: { ...inputs },
            },
          },
        });
        open?.({
          type: "success",
          message: "Successfully created exchange rate",
        });
        push("/exchangeRates");
      } else
        open?.({
          type: "error",
          message: "Make sure all fields have data",
        });
    } catch (e: any) {
      open?.({
        type: "error",
        message: e.message,
      });
    }
  };

  useEffect(() => {
    if (inputs.fromCurrencyId)
      setAvailableCurrencies(
        currencyTypes.filter((type) => type !== inputs.fromCurrencyId)
      );
    else setAvailableCurrencies(currencyTypes);
  }, [inputs.fromCurrencyId]);

  return (
    <Create isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
      <form>
        <Select
          mt={8}
          label="From Currency"
          placeholder="Select currency"
          value={inputs.fromCurrencyId}
          onChange={(e: string) => setInputs({ ...inputs, fromCurrencyId: e })}
          data={currencyTypes}
        />

        <Select
          mt={8}
          label="To Currency"
          placeholder="Select currency"
          value={inputs.toCurrencyId}
          onChange={(e: string) => setInputs({ ...inputs, toCurrencyId: e })}
          data={availableCurrencies}
        />

        <NumberInput
          mt={8}
          label="Rate"
          placeholder="rate"
          value={inputs.rate}
          onChange={(e: number) => setInputs({ ...inputs, rate: e })}
        />
      </form>
    </Create>
  );
};
