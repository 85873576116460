import { gql } from "@apollo/client";

export const GET_PROVIDER_PREFERENCES = gql`
  query FindMyPreference($phoneNumber: String!) {
    findProviderPreferences(phoneNumber: $phoneNumber) {
      fullName
      providerName
      providerNumber
    }
  }
`;
