import { gql } from "@apollo/client";

export const CREATE_TRANSACTION = gql`
  mutation createTransaction(
  $input: CreateTransactionInput
  ) {
  createTransaction(
    input: $input
  ) {
    transaction {
      amount
      senderId
      recepientId
    }
  }
}
`;
