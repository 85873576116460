import { useNavigation, useNotification } from "@pankod/refine-core";
import { Create, Select, TextInput } from "@pankod/refine-mantine";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_PROVIDER_PREFERENCE } from "graphql/providerPrefernece/create-providerPrefernece.mutation";

export enum Provider {
  ZAAD,
  EDAHAB,
}

export interface ProviderPreferenceCreateInput {
  phoneNumber: string;
  providerName: string;
  providerNumber: string;
  fullName?: string;
}

export const providerTypes: string[] = Object.keys(Provider).filter((key) =>
  isNaN(Number(key))
);

export const ProviderPreferenceCreate: React.FC = () => {
  const { push } = useNavigation();
  const { open } = useNotification();
  const [createProviderPreference, { loading }] = useMutation(
    CREATE_PROVIDER_PREFERENCE
  );
  const [inputs, setInputs] = useState<ProviderPreferenceCreateInput>({
    phoneNumber: "",
    providerName: Provider.ZAAD.toString(),
    providerNumber: "",
    fullName: "",
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { phoneNumber, providerName, providerNumber, fullName } = inputs;
      if (phoneNumber && providerName && providerNumber) {
        await createProviderPreference({
          variables: {
            input: {
              phoneNumber,
              data: {
                providerName,
                providerNumber,
                fullName,
              },
            },
          },
        });
        open?.({
          type: "success",
          message: "Successfully created provider preference",
        });
        push("/providerPreferences");
      } else
        open?.({
          type: "error",
          message: "Make sure all necessary fields have data",
        });
    } catch (e: any) {
      open?.({
        type: "error",
        message: e.message,
      });
    }
  };

  return (
    <Create isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
      <form>
        <TextInput
          mt={8}
          label="Phone Number"
          placeholder="phone number"
          type="string"
          value={inputs.phoneNumber}
          onChange={(e) =>
            setInputs({ ...inputs, phoneNumber: e.target.value.trim() })
          }
          required
        />

        <Select
          mt={8}
          label="Provider Name"
          placeholder="Select provider"
          value={inputs.providerName}
          onChange={(e: string) => setInputs({ ...inputs, providerName: e })}
          data={providerTypes}
          required
        />

        <TextInput
          mt={8}
          label="Provider Number"
          placeholder="provider number"
          type="string"
          value={inputs.providerNumber}
          onChange={(e) =>
            setInputs({ ...inputs, providerNumber: e.target.value.trim() })
          }
          required
        />

        <TextInput
          mt={8}
          label="Full Name"
          placeholder="full name"
          type="string"
          value={inputs.fullName}
          onChange={(e) =>
            setInputs({ ...inputs, fullName: e.target.value.trim() })
          }
        />
      </form>
    </Create>
  );
};
