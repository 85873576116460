import { useMutation } from "@apollo/client";
import { useNavigation, useNotification } from "@pankod/refine-core";
import { Create, NumberInput, Select, TextInput } from "@pankod/refine-mantine";
import { CREATE_CREDIT_VOUCHER } from "graphql/credit-vouchers/credit-vouchers.mutation";
import { useState } from "react";

enum Service {
    INTERNET_TOPUP = 'INTERNET_TOPUP',
    CROSS_TRANSFER = 'CROSS_TRANSFER',
    DEPOSIT = 'DEPOSIT',
    WITHDRAWAL = 'WITHDRAWAL',
}


type CreateCreditVoucherData = {
    name: string;
    service: string;
    referenceId?: string;
    discount: number;
    creditPrice: number;
}

export const services: string[] = Object.keys(Service).filter((key) =>
    isNaN(Number(key))
);

export const CreditVoucherCreate = () => {
    const { push } = useNavigation();
    const { open } = useNotification();

    const [createCreditVoucher, { loading }] = useMutation(CREATE_CREDIT_VOUCHER);
    const [inputs, setInputs] = useState<CreateCreditVoucherData>({
        name: "",
        creditPrice: 0,
        discount: 0,
        service: 'INTERNET_TOPUP',
        referenceId:''

    });


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const noneIsEmpty = Object.values(inputs).every((value) => value !== "");

        try {
            if (noneIsEmpty) {
                await createCreditVoucher({
                    variables: {
                        input: {
                            data: { ...inputs },
                        },
                    },
                });
                open?.({
                    type: "success",
                    message: "Successfully created credit voucher ",
                });
                push("/credit-vouchers");
            } else
                open?.({
                    type: "error",
                    message: "Make sure all fields have data",
                });
        } catch (e: any) {
            open?.({
                type: "error",
                message: e.message,
            });
        }
    };


    return (
        <Create isLoading={loading} saveButtonProps={{ onClick: handleSubmit }}>
            <form>
                <Select
                    mt={8}
                    label="Service"
                    placeholder="Service"
                    value={inputs.service}
                    onChange={(e: string) => setInputs({ ...inputs, service: e })}
                    data={services}
                />

                <TextInput
                    mt={8}
                    label="Name"
                    placeholder="name"
                    value={inputs.name}
                    onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                />

                <TextInput
                    mt={8}
                    label="reference"
                    placeholder="reference id(i.e internet package id)"
                    value={inputs.referenceId}
                    onChange={(e) => setInputs({ ...inputs, referenceId: e.target.value })}
                />

                <TextInput
                    mt={8}
                    label="CreditPrice"
                    placeholder="CreditPrice"
                    value={inputs.creditPrice}
                    onChange={(e) => setInputs({ ...inputs, creditPrice: +e.target.value })}
                />

                <TextInput
                    mt={8}
                    label="Discount"
                    placeholder="discount"
                    value={inputs.discount}
                    onChange={(e) => setInputs({ ...inputs, discount: +e.target.value })}
                />
            </form>
        </Create>
    );
}